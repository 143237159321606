<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-https"/>
          Mein eigenes Passwort ändern
        </CCardHeader>

        <CCardBody>
          <CForm @submit.prevent="changePassword">

            <input-password v-model="form.password" :vld="v$.form.password" label="Neues Passwort"/>
            <input-submit label="Passwort ändern" :vld="v$"/>

          </CForm>

          <FormError v-if="formError" :errormessage="formError"/>
        </CCardBody>

      </CCard>
    </CCol>

  </CRow>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { useToast } from 'vue-toastification'
import { required } from '@vuelidate/validators'
import FormError from '@/components/form-elements/Error'
import inputPassword from '@/components/form-elements/Password'
import inputSubmit from '@/components/form-elements/Submit'

export default {
  name: 'profile-password',
  components: {
    inputPassword,
    inputSubmit,
    FormError
  },
  setup () {
    return {
      v$: useVuelidate(),
      toast: useToast()
    }
  },
  validations () {
    return {
      form: {
        password: {
          required
        }
      }
    }
  },
  data () {
    return {
      formError: null,
      form: {
        password: ''
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  methods: {
    changePassword: function () {
      const self = this
      const password = this.form.password
      this.axios.patch('/user/password/change', {
        plainPassword: password
      }).then(() => {
        self.formError = null
        self.form.password = ''
        self.toast.success('Das Passwort wurde geändert.')
        self.$router.push({ path: '/dashboard' })
      }).catch((error) => {
        self.formError = error.response.data
      })
    }
  }
}
</script>
